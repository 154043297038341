<!--
 * @Author: 张阳帅
 * @Date: 2024-10-08 09:52:37
 * @LastEditTime: 2024-10-08 09:52:37
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\views\appoint\appoint.vue
-->
<template>
  <div class="msglist_page">
    <div class="page_main">
      <div class="main_item">
        <div class="item_title">
          <div class="title_cont">
            {{ msgDetail.xxbt }}
          </div>
        </div>
        <div class="item_time">
          {{ msgDetail.xxfssj }}
        </div>
        <div class="item_info">
          <div class="info_name">
            {{ msgDetail.xxnr }}
          </div>
        </div>
      </div>
    </div>  
  </div>
</template>
<script>
import { gjcModel } from '@/api/gjcApi'
export default {
    data(){
        return{
            msgDetail:{} //消息
        }
    },
    mounted() {
        this.getMsgDetailData()
    },
    methods:{
        getMsgDetailData(){
            gjcModel.getMsgDetailData(this.$route.query.id).then(res=>{
                this.msgDetail=res.data
            })
        }
    }
}
</script>
<style scoped lang='less'>
.msglist_page{
    background-color: white;
    height: 100vh;
}
.page_main{
    background-color: white;
    overflow: auto;
    .main_item{
        padding: 20px 24px;
        .item_title{
            display: flex;
            justify-content: center;
            .title_cont{
                font-size: 32px;
                color: #333333;
                font-weight: bold;
            }
        }
        .item_time{
            padding: 20px 0;    
            text-align: right;
            font-size: 26px;
            color: #999999;
        }
        .item_info{
            display: flex;
            .info_name{
                font-size: 26px;
                color: #666;
            }
        }
    }
}

</style>
